const styles = ({ typography, palette, breakpoints }) => ({
  wrap: {
    transition: 'all .25s',
    flex: '1 0 100%',
    padding: '32px 10px 43px 10px',
    backgroundColor: '#fff',
    [breakpoints.up('md')]: {
      display: 'flex',
      padding: '32px 54px 43px 60px',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }
  },
  title: {
    marginTop: 0,
    marginBottom: 32,
    textAlign: 'center',
    fontFamily: typography.fontFamily,
    fontSize: 24,
    color: '#22242d',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelText: {
    marginBottom: 6,
    marginLeft: 14,
    fontFamily: typography.fontFamily,
    fontSize: 16,
    color: '#22242d',
  },
  button: {
    marginTop: 20,
    color: '#fff',
    backgroundColor: palette.primary.main,
    borderRadius: 40,
    height: 66,
    fontFamily: typography.fontFamily,
    fontSize: 21,
    textAlign: 'center',
  },
  input: {
    height: 66,
    paddingLeft: 40,
    paddingRight: 40,
    marginBottom: 12,
    fontFamily: typography.fontFamily,
    borderRadius: 40,
    color: '#22242d',
    border: '1px solid #B9BECB',
    fontSize: 16,
    transition: 'border-color .15s',

    '&.Mui-error': {
      color: '#de5c52',
      borderColor: '#de5c52',
    },
  },
  inputFocus: {
    borderColor: '#22242d',
  },
  forgotPassword: {
    textAlign: 'right',
    marginTop: 25,
    marginBottom: 25,
  },
  forgotPasswordText: {
    fontSize: 16,
    textDecoration: 'none',
    color: '#005EFB',
    '&:visited': {
      color: '#005EFB',
    },
  },
  termsText: {
    fontSize: 16,
    textAlign: 'center',
  },
  termsLink: {
    color: '#005EFB',
    textDecoration: 'none',
    '&:visited': {
      color: '#005EFB',
    },
  },
  controlWrap: {
    '@media (min-width: 768px)': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
    }
  }
});

export default styles;
