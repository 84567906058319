import React from 'react';
import { withRouter } from 'next/router';
import { withFormik } from 'formik';
import Alert from '@material-ui/lab/Alert';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { signin } from 'store/auth/actions';
import { connect } from 'react-redux';
import schema from './schema';
import styles from './styles';

const SigninForm = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    classes,
    visible = true,
    isSubmitting,
    termsLink,
    onForgotPasswordClick,
  } = props;

  const tabIndex = visible ? 0 : -1;

  return (
    <div className={classes.wrap}>
      <form onSubmit={handleSubmit}>
        <h2 className={classes.title}>
          Sign in
        </h2>

        <label className={classes.label}>
          <span className={classes.labelText}>
            Email
          </span>
          <Input
            color="secondary"
            disableUnderline
            fullWidth
            autoComplete="section-signup email"
            classes={{
              root: classes.input,
              focused: classes.inputFocus,
            }}
            name="email"
            onChange={handleChange}
            value={values.email}
            error={errors.email && touched.email}
            type="text"
            required={false}
            inputProps={{
              tabIndex,
            }}
          />
        </label>

        <label className={classes.label}>
          <span className={classes.labelText}>
            Password
          </span>
          <Input
            color="secondary"
            fullWidth
            disableUnderline
            classes={{
              root: classes.input,
              focused: classes.inputFocus,
            }}
            name="password"
            onChange={handleChange}
            value={values.password}
            error={errors.password && touched.password}
            type="password"
            required={false}
            inputProps={{
              tabIndex,
            }}
          />
        </label>

        {errors.submit && (
          <Alert severity="error">{errors.submit}</Alert>
        )}

        <Button
          disabled={isSubmitting}
          tabIndex={tabIndex}
          fullWidth
          color="primary"
          variant="contained"
          className={classes.button}
          type="submit"
          size="large"
        >
          Sign In
        </Button>
      </form>
      <div className={classes.controlWrap}>
        <div className={classes.forgotPassword}>
          <Button
            className={classes.forgotPasswordText}
            onClick={() => onForgotPasswordClick()}
          >
            Forgot password?
          </Button>
        </div>
        <Typography component="p" className={classes.termsText}>
          By signing in you are agreeing to our
          {' '}
          <a
            href={termsLink}
            target="_blank"
            rel="noreferrer"
            className={classes.termsLink}
          >
            Terms and Conditions
          </a>
        </Typography>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    signInBooking: state.auth.signInBooking,
  };
};

const mapDispatchToProps = ({ signin });

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withFormik({
  displayName: 'SigninForm',
  mapPropsToValues: () => ({
    email: '',
    password: '',
  }),
  handleSubmit: async (values, form) => {
    const { setErrors, setSubmitting, props } = form;

    setSubmitting(true);
    await props.signin(values,
      () => (props.signInBooking ? {} : props.router.back()),
      e => setErrors({ submit: e }));
    setSubmitting(false);
  },
  validationSchema: schema,
})(withStyles(styles)(SigninForm))));
