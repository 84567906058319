import axios from 'config/axios';
import { SET_TIME, SET_BOOKED } from './actionTypes';

export const setTimeLeft = payload => async dispatch => {
  dispatch({
    type: SET_TIME,
    payload,
  });
};

export const setBooked = payload => async dispatch => {
  dispatch({
    type: SET_BOOKED,
    payload,
  });
};

export const sendContactDetails = (body, successCallback, errorCallback) => async () => {
  try {
    const response = await axios.post('support/contact-details', body);
    const { code: statusCode, message = '' } = response.data;
    if (statusCode < 200 || statusCode >= 300) {
      throw Error(message);
    }
    if (successCallback && typeof successCallback === 'function') {
      successCallback(message);
    }
  } catch (e) {
    console.error(e);
    errorCallback(e.message);
  }
};
