import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { logout } from 'store/auth/actions';
import { addModalQuery } from 'helpers/slug';
import styles from './styles';

const useStyles = makeStyles(styles);

const ProfileBar = props => {
  const classes = useStyles();
  const { profile, logout, router } = props;

  const onLoginPressed = () => {
    addModalQuery(router, 'sign-in');
  };

  const onViewBookings = () => {
    router.push('/bookings');
  };

  const onContactUs = () => {
    addModalQuery(router, 'contact-us');
  };

  if (!profile) {
    return (
      <div className={classes.wrap}>
        <div className={classes.bar}>
          <Button
            className={classes.button}
            color="primary"
            variant="text"
            onClick={onLoginPressed}
          >
            Sign In
          </Button>
        </div>
        <Button
          className={classes.button}
          color="primary"
          variant="text"
          onClick={onContactUs}
        >
          Contact Us
        </Button>
      </div>
    );
  }

  const { firstName } = profile;

  return (
    <div className={classes.wrap}>
      <div className={classes.bar}>
        <Typography className={classes.greet}>
          {`Hi ${firstName},`}
        </Typography>

        <div className={classes.ctaContainer}>
          <Button
            className={classes.button}
            color="primary"
            variant="text"
            onClick={onViewBookings}
          >
            View Your Bookings
          </Button>

          <div className={classes.divider} />

          <Button
            className={classes.button}
            color="primary"
            variant="text"
            onClick={logout}
          >
            Log Out
          </Button>
        </div>
      </div>
      <Button
        className={classes.button}
        color="primary"
        variant="text"
        onClick={onContactUs}
      >
        Contact Us
      </Button>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  profile: auth.profile,
});

const mapDispatchToProps = ({
  logout,
});

const ProfileBarRedux = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileBar);
export default withRouter(ProfileBarRedux);
