const styles = ({ palette, breakpoints }) => ({
  wrap: {
    display: 'flex',
    flexDirection: ' column',
    alignItems: ' flex-end',
    [breakpoints.up('md')]: {
      paddingRight: 20,
    },
  },
  bar: {
    textAlign: 'end',
    [breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
  },
  ctaContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [breakpoints.up('md')]: {
      alignItems: 'center',
    },
  },
  greet: {
    fontSize: '0.8rem',
    fontWeight: 700,
    [breakpoints.up('md')]: {
      marginRight: '0.3em',
      fontSize: '1rem',
    },
  },
  divider: {
    position: 'relative',
    marginLeft: '0.3em',
    marginRight: '0.3em',
    fontWeight: 700,
    '&::after': {
      content: '"|"',
    },
  },
  button: {
    fontSize: '0.8rem',
    minWidth: 0,
    padding: 0,
    fontWeight: 700,
    '&:hover': {
      backgroundColor: 'transparent',
      color: palette.primary.dark,
    },
    [breakpoints.up('md')]: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
  },
});

export default styles;
