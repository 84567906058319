import * as yup from 'yup';

export default yup.object({
  firstName: yup.string()
    .required('First name should not be empty'),
  lastName: yup.string()
    .required('First name should not be empty'),
  email: yup.string()
    .email('Invalid email address')
    .required(),
  phone: yup.string()
    .required('Phone number should not be empty'),
});
