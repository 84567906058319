import * as yup from 'yup';

export default yup.object({
  password: yup.string()
    .required(),
  email: yup.string()
    .test('admin-email', 'Invalid email address', value => {
      const emailRegexp = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/g;
      return value ? emailRegexp.test(value.toLowerCase()) : false;
    })
    .required(),
});
