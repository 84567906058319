const styles = ({ typography, palette, breakpoints }) => ({
  root: {
    flexGrow: 1,
    marginBottom: 12,
    [breakpoints.up('md')]: {
      marginBottom: 43,
    },
  },
  header: {
    paddingTop: 8,
    paddingBottom: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',
    [breakpoints.up('md')]: {
      padding: 0,
      minHeight: 104,
    },
  },
  menuButton: {
    borderRadius: 0,
    position: 'absolute',
    padding: 0,
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    minHeight: 26,
    [breakpoints.up('md')]: {
      minHeight: 56,
    },
  },
  image: {
    height: '100%',
    width: '100%',
  },
  backButton: {
    position: 'absolute',
    right: '100%',
    marginRight: 30,
    borderRadius: 8,
    display: 'none',
    [breakpoints.up('xl')]: {
      display: 'block',
    },
  },
  backImage: {
    width: 9,
    height: 17,
    marginRight: 18,
  },
  backText: {
    color: palette.primary.main,
    fontWeight: '900',
    fontFamily: typography.fontFamily,
    fontSize: 18,
    lineHeight: 1.22222222,
  },
  navigation: {
    height: 26,
    width: 66,
    position: 'relative',
    [breakpoints.up('md')]: {
      height: 60,
      width: 148,
    },
  },
});

export default styles;
