import React from 'react';
import { useRouter } from 'next/router';
import withStyles from '@material-ui/styles/withStyles';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import theme from 'theme';
import ProfileBar from 'components/ProfileBar';
import BackIcon from 'assets/chevron-left.svg';
import styles from './styles';

const WP_URL = process.env.NEXT_PUBLIC_WP_URL || '/';

const Header = props => {
  const { classes } = props;
  const { logo, baseUrl } = theme.palette;
  const router = useRouter();
  const hasBack = router.route !== '/';
  const homeURL = baseUrl || WP_URL;

  const handleLogoClick = () => {
    router.push(homeURL);
  };

  return (
    <div className={classes.root}>
      <AppBar
        className={classes.header}
        position="static"
        color="transparent"
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters className={classes.container}>
            <div className={classes.navigation}>
              {hasBack && (
                <IconButton
                  className={classes.backButton}
                  onClick={() => router.back()}
                >
                  <img
                    className={classes.backImage}
                    src={BackIcon}
                    alt=""
                  />
                  <span className={classes.backText}>Back</span>
                </IconButton>
              )}
              <IconButton
                className={classes.menuButton}
                onClick={handleLogoClick}
              >
                {logo && (
                  <img
                    className={classes.image}
                    src={logo}
                    alt="TutorTime"
                  />
                )}
              </IconButton>
            </div>
            <ProfileBar />
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default withStyles(styles)(Header);
