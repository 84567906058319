import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import withStyles from '@material-ui/styles/withStyles';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import styles from './styles';

const ContactUsModal = props => {
  const {
    classes,
    open,
    onClose,
    termsLink,
  } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        root: classes.modal,
        container: classes.container,
        paper: classes.paper,
      }}
    >
      <div className={classes.head}>
        <Button className={classes.back} onClick={onClose}>
          <BackIcon />
        </Button>
      </div>
      <div className={classes.bodyWrap}>
        <h2 className={classes.title}>
          Contact Us
        </h2>
        <Typography component="p" className={classes.text}>
          If you have any questions please contact us on
          <br />
          <a
            href="tel:1300788867"
            className={classes.link}
          >
            1300 788  867
          </a>
          {' '}
          or email us at
          {' '}
          <a
            href="mailto:sophiewhitehouse@tutortimetutors.com"
            className={classes.link}
          >
            sophiewhitehouse@tutortimetutors.com
          </a>
        </Typography>
        <a
          href={termsLink}
          target="_blank"
          rel="noreferrer"
          className={classes.link}
        >
          Terms and Conditions
        </a>
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(ContactUsModal);
