import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';
import { useRouter } from 'next/router';

import { setTimeLeft } from 'store/timer/actions';
import { addModalQuery } from 'helpers/slug';
import SigninModal from 'components/SigninModal';
import ForgotPasswordModal from 'components/ForgotPasswordModal';
import LeaveDetailsModal from 'components/LeaveDetailsModal';
import ContactUsModal from 'components/ContactUsModal';
import Header from './Header';

const Page = options => WrappedComponent => {
  const { header = true, title = '' } = options;
  const PageHOC = props => {
    const { timeLeft, setTimeLeft, booked, ...otherProps } = props;
    const router = useRouter();
    const { view } = router.query;

    useEffect(() => {
      const { query } = router;
      const isAdminFlow = router.pathname.includes('admin');

      /* stop the timer if the modal is already open */
      if (query.view === 'leave-details' || booked || isAdminFlow) {
        setTimeLeft(0);
        return;
      }

      if (timeLeft > 0) {
        const timer = setTimeout(() => {
          setTimeLeft(timeLeft - 1);
        }, 1000);

        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
      }

      /* wait 10 seconds if another modal is already open */
      if (query.view) {
        setTimeLeft(10);
        return;
      }

      showLeaveDetailsModal();
    }, [timeLeft, booked]);

    const showLeaveDetailsModal = () => {
      addModalQuery(router, 'leave-details');
    };

    const onForgotPasswordClick = () => {
      addModalQuery(router, 'forgot-password');
    };

    return (
      <div className="Layout">
        {header && <Header title={title} />}
        <div className="Layout-Base">
          <Container maxWidth="lg">
            <WrappedComponent {...otherProps} />
          </Container>
        </div>

        {view === 'sign-in' && (
          <SigninModal
            open
            onClose={() => router.back()}
            termsLink={process.env.NEXT_PUBLIC_TERMS_URL}
            onForgotPasswordClick={onForgotPasswordClick}
          />
        )}

        {view === 'forgot-password' && (
          <ForgotPasswordModal open onClose={() => router.back()} />
        )}

        {view === 'contact-us' && (
          <ContactUsModal
            open
            onClose={() => router.back()}
            termsLink={process.env.NEXT_PUBLIC_TERMS_URL}
          />
        )}

        {view === 'leave-details' && (
          <LeaveDetailsModal open onClose={() => router.back()} />
        )}
      </div>
    );
  };

  const mapStateToProps = state => ({
    timeLeft: state.timer.timeLeft,
    booked: state.timer.booked,
  });

  const mapDispatchToProps = {
    setTimeLeft,
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PageHOC);
};

export default Page;
