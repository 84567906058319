import React from 'react';
import { withRouter } from 'next/router';
import { withFormik } from 'formik';
import Alert from '@material-ui/lab/Alert';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { sendContactDetails } from 'store/timer/actions';

import { Typography } from '@material-ui/core';
import schema from './schema';
import styles from './styles';

const LeaveDetailsForm = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    classes,
    visible = true,
    isSubmitting,
    status,
  } = props;

  const tabIndex = visible ? 0 : -1;

  return (
    <div className={classes.wrap}>
      <form onSubmit={handleSubmit}>
        <h2 className={classes.title}>
          Would you like more information about TutorTime and our Services?
        </h2>
        <p className={classes.info}>
          Leave&nbsp;us your details and we&rsquo;ll get back to&nbsp;you.
        </p>

        <label className={classes.label}>
          <span className={classes.labelText}>
            First Name
          </span>
          <Input
            color="secondary"
            disableUnderline
            fullWidth
            autoComplete="section-details firstName"
            classes={{
              root: classes.input,
              focused: classes.inputFocus,
            }}
            name="firstName"
            onChange={handleChange}
            value={values.firstName}
            error={errors.firstName && touched.firstName}
            type="text"
            required={false}
            inputProps={{
              tabIndex,
            }}
          />
        </label>

        <label className={classes.label}>
          <span className={classes.labelText}>
            Last Name
          </span>
          <Input
            color="secondary"
            disableUnderline
            fullWidth
            autoComplete="section-details lastName"
            classes={{
              root: classes.input,
              focused: classes.inputFocus,
            }}
            name="lastName"
            onChange={handleChange}
            value={values.lastName}
            error={errors.lastName && touched.lastName}
            type="text"
            required={false}
            inputProps={{
              tabIndex,
            }}
          />
        </label>

        <label className={classes.label}>
          <span className={classes.labelText}>
            Email address
          </span>
          <Input
            color="secondary"
            disableUnderline
            fullWidth
            autoComplete="section-details email"
            classes={{
              root: classes.input,
              focused: classes.inputFocus,
            }}
            name="email"
            onChange={handleChange}
            value={values.email}
            error={errors.email && touched.email}
            type="email"
            required={false}
            inputProps={{
              tabIndex,
            }}
          />
        </label>

        <label className={classes.label}>
          <span className={classes.labelText}>
            Phone number
          </span>
          <Input
            color="secondary"
            disableUnderline
            fullWidth
            autoComplete="section-details phone"
            classes={{
              root: classes.input,
              focused: classes.inputFocus,
            }}
            name="phone"
            onChange={handleChange}
            value={values.phone}
            error={errors.phone && touched.phone}
            type="phone"
            required={false}
            inputProps={{
              tabIndex,
            }}
          />
        </label>

        {errors.submit && (
          <Alert severity="error">{errors.submit}</Alert>
        )}

        {status
          && (
            <Typography className={classes.successText}>
              {status.success}
            </Typography>
          )}

        <Button
          disabled={isSubmitting}
          tabIndex={tabIndex}
          fullWidth
          color="primary"
          variant="contained"
          className={classes.button}
          type="submit"
          size="large"
        >
          Contact me
        </Button>
      </form>
    </div>
  );
};

const mapDispatchToProps = ({ sendContactDetails });

export default connect(null, mapDispatchToProps)(withRouter(withFormik({
  displayName: 'ForgetPasswordForm',
  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  }),
  handleSubmit: async (values, form) => {
    const { setErrors, setSubmitting, props, setStatus } = form;

    setSubmitting(true);
    await props.sendContactDetails(values,
      msg => {
        setStatus({ success: msg });
        props.router.back();
      },
      e => {
        setErrors({ submit: e });
        setStatus({ success: '' });
      });
    setSubmitting(false);
  },
  validationSchema: schema,
})(withStyles(styles)(LeaveDetailsForm))));
