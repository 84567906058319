import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import withStyles from '@material-ui/styles/withStyles';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';

import SigninForm from './SigninForm';
import styles from './styles';

const SigninModal = props => {
  const {
    classes,
    open,
    onClose,
    termsLink,
    onForgotPasswordClick,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        root: classes.modal,
        container: classes.container,
        paper: classes.paper,
      }}
    >
      <div className={classes.head}>
        <Button className={classes.back} onClick={onClose}>
          <BackIcon />
        </Button>
      </div>
      <SigninForm termsLink={termsLink} onForgotPasswordClick={onForgotPasswordClick} />
    </Dialog>
  );
};

export default withStyles(styles)(SigninModal);
