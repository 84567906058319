import { Object } from 'window-or-global';

export const slugToText = slug => {
  if (typeof slug === 'object') {
    return slug.map(val => transformSlugToString(val));
  }

  return transformSlugToString(slug);
};

export const textToSlug = text => {
  if (!text) return;

  let result = text.toLowerCase();
  while (result.indexOf('-') !== -1) {
    result = result.replace('-', '_');
  }
  while (result.indexOf(' ') !== -1) {
    result = result.replace(' ', '-');
  }
  return result;
};

const transformSlugToString = slug => {
  let result = slug.toLowerCase();
  while (result.indexOf('-') !== -1) {
    result = result.replace('-', ' ');
  }
  while (result.indexOf('_') !== -1) {
    result = result.replace('_', '-');
  }
  return result;
};

export const findInObjectArray = (array, key, value, useLowerCase) => {
  return array.find(val => {
    if (typeof value === 'string' && useLowerCase) {
      return val[key].toLowerCase() === value.toLowerCase();
    }

    return val[key] === value;
  });
};

export const findById = (array, id) => {
  return findInObjectArray(array, 'id', id, false);
};

export const getURLParams = query => {
  const {
    date,
    'start-time': startTimeEncoded,
    'end-time': endTimeEncoded,
    ...rest
  } = query;

  const params = Object.keys(rest).reduce((total, current) => {
    return {
      ...total,
      [current]: slugToText(rest[current]),
    };
  }, {});

  params.date = date;
  const startTime = startTimeEncoded?.replace('-', ':');
  const endTime = endTimeEncoded?.replace('-', ':');
  params.time = (startTime && endTime) ? `${startTime} - ${endTime}` : undefined;
  return params;
};

export const addModalQuery = (router, modalName, method = 'push') => {
  addRouterQuery(router, 'view', modalName, method);
};

export const addRouterQuery = (router, name, value, method = 'push') => {
  const { asPath, query } = router;
  const baseUrl = asPath.split('?')[0];
  router[method](
    {
      query: {
        ...query,
        [name]: value,
      },
    },
    {
      pathname: baseUrl,
      query: {
        ...query,
        [name]: value,
      },
    },
    {
      shallow: true,
    },
  );
};

const removeParamFromQuery = (query, param) => {
  return Object.keys(query).reduce((total, current) => {
    if (current === param) return total;

    return {
      ...total,
      [current]: query[current],
    };
  }, {});
};

export const removeRouterQuery = (router, name, method) => {
  const { asPath, query } = router;
  const newQuery = removeParamFromQuery(query, name);
  const baseUrl = asPath.split('?')[0];
  router[method](
    {
      query: { ...newQuery },
    },
    {
      pathname: baseUrl,
      query: { ...newQuery },
    },
    {
      shallow: true,
    },
  );
};
