import React, { useState, useEffect } from 'react';
import { withRouter } from 'next/router';
import { withFormik } from 'formik';
import Alert from '@material-ui/lab/Alert';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { resetPassword } from 'store/auth/actions';

import { Typography } from '@material-ui/core';
import schema from './schema';
import styles from './styles';

const ForgetPasswordForm = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    classes,
    visible = true,
    isSubmitting,
    status,
  } = props;

  const tabIndex = visible ? 0 : -1;

  return (
    <div className={classes.wrap}>
      <form onSubmit={handleSubmit}>
        <h2 className={classes.title}>
          Reset password
        </h2>

        <label className={classes.label}>
          <span className={classes.labelText}>
            Email
          </span>
          <Input
            color="secondary"
            disableUnderline
            fullWidth
            autoComplete="section-signup email"
            classes={{
              root: classes.input,
              focused: classes.inputFocus,
            }}
            name="email"
            onChange={handleChange}
            value={values.email}
            error={errors.email && touched.email}
            type="text"
            required={false}
            inputProps={{
              tabIndex,
            }}
          />
        </label>

        {errors.submit && (
          <Alert severity="error">{errors.submit}</Alert>
        )}

        {status
          && (
            <Typography className={classes.successText}>
              {status.success}
            </Typography>
          )}

        <Button
          disabled={isSubmitting}
          tabIndex={tabIndex}
          fullWidth
          color="primary"
          variant="contained"
          className={classes.button}
          type="submit"
          size="large"
        >
          Reset
        </Button>
      </form>
    </div>
  );
};

const mapDispatchToProps = ({ resetPassword });

export default connect(null, mapDispatchToProps)(withRouter(withFormik({
  displayName: 'ForgetPasswordForm',
  mapPropsToValues: () => ({
    email: '',
  }),
  handleSubmit: async (values, form) => {
    const { setErrors, setSubmitting, props, setStatus } = form;

    setSubmitting(true);
    await props.resetPassword(values,
      msg => setStatus({ success: msg }),
      e => {
        setErrors({ submit: e });
        setStatus({ success: '' });
      });
    setSubmitting(false);
  },
  validationSchema: schema,
})(withStyles(styles)(ForgetPasswordForm))));
