const styles = ({ breakpoints }) => ({
  modal: {
    [breakpoints.up('md')]: {
      width: 490,
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
    },
  },
  container: {
    width: '100%',
    [breakpoints.up('md')]: {
      height: 675,
    },
  },
  paper: {
    margin: 0,
    width: '100%',
    maxHeight: 'none',
    [breakpoints.up('md')]: {
      borderRadius: 10,
    },
  },
  head: {
    display: 'flex',
    zIndex: 1,
    [breakpoints.up('md')]: {
      position: 'absolute',
    },
  },
  back: {
    minWidth: 0,
    width: 32,
    height: 32,
    padding: 0,
    position: 'absolute',
    top: 33,
    left: 24,
  },
});

export default styles;
