const styles = ({ typography, breakpoints, palette }) => ({
  modal: {
    [breakpoints.up('md')]: {
      width: 490,
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
    },
  },
  container: {
    width: '100%',
    [breakpoints.up('md')]: {
      height: 675,
    },
  },
  paper: {
    margin: 0,
    width: '100%',
    maxHeight: 'none',
    [breakpoints.up('md')]: {
      borderRadius: 10,
    },
  },
  head: {
    display: 'flex',
    zIndex: 1,
    [breakpoints.up('md')]: {
      position: 'absolute',
    },
  },
  bodyWrap: {
    transition: 'all .25s',
    flex: '1 0 100%',
    padding: '32px 10px 43px 10px',
    textAlign: 'center',
    backgroundColor: '#fff',
    [breakpoints.up('md')]: {
      display: 'flex',
      padding: '32px 54px 43px 60px',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  text: {
    fontSize: 16,
    marginBottom: 20,
  },
  link: {
    color: palette.primary.main,
    fontSize: 16,
    textDecoration: 'none',
    '&:visited': {
      color: palette.primary.main,
    },
  },
  back: {
    minWidth: 0,
    width: 32,
    height: 32,
    padding: 0,
    position: 'absolute',
    top: 33,
    left: 24,
  },
  title: {
    marginTop: 0,
    marginBottom: 32,
    textAlign: 'center',
    fontFamily: typography.fontFamily,
    fontSize: 24,
    color: palette.text.primary,
  },
});

export default styles;
